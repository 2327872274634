<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Inventory Items
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-if="canViewThisAction('create', 'InventoryItem')"
          variant="success"
          class="mobile_create_button"
          :to="{ name: 'inventory-inventory-items-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Register New Item</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectAllInventoryItemCheckbox"
                :indeterminate="isSelectAllInventoryItemCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              />
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedInventoryItems.length"
            >
              <b-dropdown-item
                @click="selectedStockPRPage()"
              >
                Create Purchase Request
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                id="dropdown-Team-form"
                ref="filter_dropdown_inventory_items"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <inventory-item-filter
                  :created-at.sync="createdAtFilter"
                  :category.sync="categoryFilter"
                  :category-options="categoryOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="categoryFilter.length || createdAtFilter">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="category in categoryFilter"
                  :key="category"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromCategoryFilter(category)"
                  >
                    <span class="text-capitalize">{{ resolveCategoryName(category) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refInventoryItemsTable"
        class="position-relative min-height-table"
        :items="fetchInventoryItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-form-checkbox
            :checked="selectedInventoryItems.includes(data.item._id)"
            @change="toggleSelectedInventoryItems(data.item._id)"
            @click.native.stop
          >
            <div class="d-flex">
              <b-img
                class="mb-1 mb-sm-0 user__avatar img-preview-block"
                :src="data.item.image ? data.item.image : defaultImage"
              />
              <div
                class="ml-1"
              >
                <span class="text-bold-black">{{ data.item.name || '-' }}</span>
                <br>
                <span class="text-muted">{{ data.item.sku || '-' }}</span>
              </div>
            </div>
          </b-form-checkbox>
        </template>

        <!-- Column: category -->
        <template #cell(categoryTitle)="data">
          <span class="">{{ data.item.categoryTitle ? data.item.categoryTitle : '-' }}</span>
        </template>

        <!-- Column: brand -->
        <template #cell(brand)="data">
          <span class="">{{ data.item.brand ? data.item.brand : '-' }}</span>
        </template>

        <!-- Column: minStock -->
        <template #cell(minStock)="data">
          <span class="">{{ (data.item.minStock && data.item.minStock !== 0) ? data.item.minStock : '-' }}</span>
        </template>

        <!-- Column: createdAt -->
        <template #cell(createdAt)="data">
          <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'InventoryItem') || canViewThisAction('update', 'InventoryItem') || canViewThisAction('delete', 'InventoryItem')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item
              v-if="canViewThisAction('show', 'InventoryItem')"
              :to="{ name: 'inventory-inventory-items-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="canViewThisAction('update', 'InventoryItem')"
              :to="{ name: 'inventory-inventory-items-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'InventoryItem')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="createPRForSingleItem(data.item)"
            >
              <span class="align-middle ml-50">Create Purchase Request</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalInventoryItems > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInventoryItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BImg, BDropdown, BDropdownItem, BPagination, BFormTag, BFormCheckbox,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
// eslint-disable-next-line import/no-cycle
import useInventoryItemList from './useAllInventoryItemList'
import inventoryItemStoreModule from './inventoryItemStoreModule'
import inventoryItemFilter from './InventoryItemFilter.vue'

export default {
  components: {
    // UserListAddNew,
    BFormTag,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BFormCheckbox,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    inventoryItemFilter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      categoryOptions: [],
    }
  },

  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categoryOptions = response.data.categories ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_inventory_items.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_inventory_items.show(true)
    },
    removeFromCategoryFilter(category) {
      const index = this.categoryFilter.indexOf(category)
      if (index > -1) {
        this.categoryFilter.splice(index, 1)
      }
    },
    clearAllFilters() {
      this.categoryFilter = []
      this.createdAtFilter = ''
    },
    resolveCategoryName(id) {
      const category = this.categoryOptions.find(o => o._id === id)
      if (category) {
        return category.name || ''
      }
      return ''
    },
    createPRForSingleItem(item) {
      const items = []
      items.push(item)
      localStorage.setItem('prItems', JSON.stringify(items))
      this.$router.push({ name: 'purchasing-purchase-requests-create' })
    },
    selectedStockPRPage() {
      this.items = []
      this.selectedInventoryItems.forEach(item => {
        const stockSingleItem = this.itemListData.find(stockObj => stockObj._id === item)
        this.items.push(stockSingleItem)
      })
      if (this.items.length) {
        localStorage.setItem('prItems', JSON.stringify(this.items))
        this.$router.push({ name: 'purchasing-purchase-requests-create' })
      }
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Warning!',
        text: ' Are you sure you want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.get(`inventory/item/${id}/check-status`)
              .then(response => {
                if (response.data.exists) {
                  this.$swal({
                    title: 'Are You Sure?',
                    text: 'This item has stocks in inventory. It will be removed from the inventory as well.',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/warning.png'),
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Delete!',
                    cancelButtonText: 'No, Cancel',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-primary ml-1',
                    },
                    buttonsStyling: false,
                  })
                    .then(confirm => {
                      if (confirm.value) {
                        this.toDeletedID = id
                        this.deleteInventoryItems()
                      }
                    })
                } else {
                  this.toDeletedID = id
                  this.deleteInventoryItems()
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const INVENTORY_ITEMS_APP_STORE_MODULE_NAME = 'app-inventory-items'

    // Register module
    if (!store.hasModule(INVENTORY_ITEMS_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_ITEMS_APP_STORE_MODULE_NAME, inventoryItemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_ITEMS_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_ITEMS_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchInventoryItems,
      tableColumns,
      perPage,
      currentPage,
      totalInventoryItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInventoryItemsTable,
      refetchData,

      toDeletedID,
      deleteInventoryItems,
      currentDataLength,
      itemListData,

      // UI
      resolveInventoryItemsStatusVariant,

      // Extra Filters
      categoryFilter,
      createdAtFilter,
    } = useInventoryItemList()

    if (searchQuery.value) {
      showSearchBar.value = true
    }

    const selectedInventoryItems = ref([])
    const toggleSelectedInventoryItems = itemId => {
      const inventoryItemIndex = selectedInventoryItems.value.indexOf(itemId)

      if (inventoryItemIndex === -1) selectedInventoryItems.value.push(itemId)
      else selectedInventoryItems.value.splice(inventoryItemIndex, 1)
    }
    const selectAllInventoryItemCheckbox = computed(() => itemListData.value.length && (itemListData.value.length === selectedInventoryItems.value.length))
    const isSelectAllInventoryItemCheckboxIndeterminate = computed(() => Boolean(selectedInventoryItems.value.length) && itemListData.value.length !== selectedInventoryItems.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedInventoryItems.value = val ? itemListData.value.map(sData => sData._id) : []
    }

    return {

      // Sidebar

      fetchInventoryItems,
      tableColumns,
      perPage,
      currentPage,
      totalInventoryItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInventoryItemsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteInventoryItems,
      currentDataLength,
      itemListData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveInventoryItemsStatusVariant,

      // Extra Filters
      categoryFilter,
      createdAtFilter,

      selectAllInventoryItemCheckbox,
      isSelectAllInventoryItemCheckboxIndeterminate,
      selectedInventoryItems,
      toggleSelectedInventoryItems,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
