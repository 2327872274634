import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useInventoryItemsList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refInventoryItemsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'item name',
      tdClass: 'width-30',
      sortable: true,
    },
    {
      key: 'categoryTitle',
      label: 'category',
      sortable: true,
    },
    {
      key: 'brand',
      label: 'brand',
      sortable: true,
    },
    {
      key: 'minStock',
      label: 'min. stock',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'created',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalInventoryItems = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const categoryFilter = ref([])
  if (Array.isArray(route.query.category)) {
    categoryFilter.value = route.query.category
  } else if (route.query.category) {
    categoryFilter.value = [route.query.category]
  }
  const createdAtFilter = ref(route.query.createdAt || '')
  const toDeletedID = ref(null)
  const currentDataLength = ref(0)
  const itemListData = ref([])
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refInventoryItemsTable.value ? refInventoryItemsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInventoryItems.value,
    }
  })

  const refetchData = () => {
    refInventoryItemsTable.value.refresh()
  }

  watch([searchQuery, categoryFilter, createdAtFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchInventoryItems = (ctx, callback) => {
    store
      .dispatch('app-inventory-items/fetchInventoryItems', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category: categoryFilter.value,
        createdAt: createdAtFilter.value,
      },
      { root: true })
      .then(response => {
        const { items, total } = response.data
        itemListData.value = items
        currentDataLength.value = items.length
        callback(items)
        totalInventoryItems.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              category: categoryFilter.value,
              createdAt: createdAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteInventoryItems = () => {
    store
      .dispatch('app-inventory-items/deleteInventoryItems', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInventoryItemsStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    return 'primary'
  }

  return {
    fetchInventoryItems,
    tableColumns,
    perPage,
    currentPage,
    totalInventoryItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInventoryItemsTable,

    resolveInventoryItemsStatusVariant,
    refetchData,

    toDeletedID,
    deleteInventoryItems,
    currentDataLength,
    itemListData,

    // Extra Filters
    categoryFilter,
    createdAtFilter,
  }
}
